import api from '@/base/utils/request';

export const fetchVideoList = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountVideo/index',
    method: 'post',
    data
  })
}

export const getAllCategories = () => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/all',
    method: 'post',
  })
}

export const setVideo = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountVideo/save',
    method: 'post',
    data
  })
}

export const updateVideoSort = (id, sort) => {
  return api({
    url: '/admin/wx_open/WxAccountVideo/updateSort',
    method: 'post',
    data: {
      id,
      sort
    }
  })
}

export const removeVideo = (id) => {
  return api({
    url: '/admin/wx_open/WxAccountVideo/del',
    method: 'post',
    data: { id }
  })
}
