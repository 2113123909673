import { api } from "@/base/utils/request";

export const getGovChannels = (data) => {
  return api({
    url: '/admin/gov/WxAccountVideo/index',
    method: 'post',
    data
  })
}

export const getGovChannelCategories = (data) => {
  return api({
    url: '/admin/gov/WxAccountVideo/categories',
    method: 'post',
    data
  })
}
