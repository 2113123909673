<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <el-form-item label="视频ID：" prop="feed_id">
          <el-input v-model="form.feed_id" placeholder="请输入" @keyup.enter.native="ok" clearable></el-input>
        </el-form-item>
        <el-form-item label="视频号ID：" prop="account_id">
          <el-input v-model="form.account_id" placeholder="请输入" @keyup.enter.native="ok" clearable></el-input>
        </el-form-item>
        <el-form-item v-if="!isGov" label="分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="(item, index) in categoryArr" :key="item.id" :value="item.id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-else label="分类：" prop="category_name">
          <el-select v-model="form.category_name" placeholder="请选择">
            <el-option label="全部" :value="-1"></el-option>
            <el-option v-for="(item, index) in categoryArr" :key="item.value" :value="item.value"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="创建时间："
                          start-prop="start_time" :start-time.sync="form.start_time"
                          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import {allCategories} from "@/modules/wx-channels/api/category-list";
import { getGovChannelCategories } from "@/modules/gov/api/channel-library";
export default {
  name: 'channel-list-filter',
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
    isGov: Boolean,
  },
  data() {
    return {
      rules: {},
      categoryArr: [],
    };
  },
  methods: {
    // 获取分类选项列表
    getCategoryArr() {
      if (this.isGov) {
        getGovChannelCategories().then(res => {
          this.categoryArr = res.data;
        }).catch(err => {
        })
        return
      }
      allCategories()
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCategoryArr();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  ::v-deep.el-input__inner {
    width: 67px !important;
  }
}
</style>
