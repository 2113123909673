import api from '@/base/utils/request';

// 分类管理列表数据
export const fetchCategoryList = data => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/index',
    method: 'post',
    data,
  });
};

/**
 * 新增、编辑
 * @param data
 * @returns {AxiosPromise}
 */
export const setCategory = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/save',
    method: 'post',
    data
  })
}

/**
 * 转移
 * @param data
 * @returns {AxiosPromise}
 */
export const doMove = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/move',
    method: 'post',
    data
  })
}

/**
 * 删除，没有回收站
 * @param id
 * @returns {AxiosPromise}
 */
export const removeCategory = (id) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/del',
    method: 'post',
    data: {id}
  })
}

/**
 * 修改排序
 * @param id
 * @param sort
 * @returns {AxiosPromise}
 */
export const updateCategorySort = (id, sort) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/updateSort',
    method: 'post',
    data: { id, sort }
  })
}

/**
 * 更新状态
 * @param id
 * @param is_show
 * @returns {AxiosPromise}
 */
export const updateCategoryShow = (id, is_show) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/updateShow',
    method: 'post',
    data: { id, is_show }
  })
}

export const allCategories = () => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/all',
    method: 'post'
  })
}

export const mergeCategory = (data) => {
  return api({
    url: '/admin/wx_open/WxAccountCategory/move',
    method: 'post',
    data
  })
}
