<template>
  <el-dialog :visible="value" :title="title" @close="close" class="dialog-vertical" width="540px">
    <el-form :model="form" class="small-form" label-width="85px">
      <el-form-item label="视频封面：" prop="" required>
        <single-media-wall v-model="form.cover" :width="141" :height="84" :border-radius="2" add-text="">
          <div slot="info" class="info"><i class="el-icon-info"></i>具体尺寸见"页面装修"组件选中的模板尺寸</div>
        </single-media-wall>
      </el-form-item>
      <el-form-item label="视频标题：" prop="" required>
        <el-input v-model="form.title" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="视频号ID：" prop="" required>
        <el-input v-model="form.account_id" placeholder="请输入"/>
<!--        <div class="info">-->
<!--          <i class="el-icon-info"></i>-->
<!--          <a href="https://channels.weixin.qq.com/" target="_blank">视频号ID获取方式</a>-->
<!--        </div>-->
      </el-form-item>
      <el-form-item label="视频ID：" prop="" required>
        <el-input v-model="form.feed_id" placeholder="请输入" :rows="3" type="textarea"/>
<!--        <div class="info">-->
<!--          <i class="el-icon-info"></i>-->
<!--          <a href="https://channels.weixin.qq.com/" target="_blank">视频ID获取方式</a>-->
<!--        </div>-->
      </el-form-item>
      <el-form-item label="分类：" prop="" required>
        <el-select v-model="form.category_id" placeholder="请选择">
          <el-option v-for="(item, index) in categoryArr" :key="item.id" :value="item.id"
                     :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" :loading="loading" @click="confirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
  import {setVideo} from "@/modules/wx-channels/api/video-list";
  import {allCategories} from "@/modules/wx-channels/api/category-list";

  export default {
    name: "AddVideo",
    components: {SingleMediaWall},
    props: {
      // v-model
      value: {
        type: Boolean,
        default: false
      },
      form: {
        type: Object,
        default: function () {
          return {
            id: 0,
            cover: '',
          }
        }
      },
    },
    data() {
      return {
        loading: false,
        // rules: {},
        categoryArr: [],
      }
    },
    computed: {
      title() {
        return (this.form.id ? '编辑' : '添加') + '视频'
      }
    },
    watch: {
      value(newValue) {
        if (newValue) {
          if (!this.form.id) return
          let name = this.form.category_name
          this.$set(this.form, 'category_id', this.categoryArr.find(el => el.name === name).id)
        }
      }
    },
    created() {
      allCategories()
        .then((res) => {
          this.categoryArr = res.data;
        })
        .catch((err) => {});
    },
    methods: {
      close() {
        this.$emit('input', false)
      },
      confirm() {
        this.loading = true
        setVideo(this.form).then(res => {
          this.$message.success(res.msg)
          this.loading = false
          this.close()
          this.$emit('refresh')
        }).catch(err => {
          this.loading = false
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
